.directions-slider {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  display: none;

  .react-slideshow-container + ul.indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin-top: 50px;

    .indicator {
      cursor: pointer;
      text-align: center;
      margin: 0;
      border-radius: 50%;
      width: 11px;
      height: 11px;
      background: #797979;
    }

    .indicator.active {
      width: 29px;
      border-radius: 60px;
      background: #fbda70;
    }
  }
}

.swiper-pagination-bullet {
  background-color: #FFFFFF;
}

@media screen and (max-width: 500px) {
  .directions-slider {
    width: 100%;
    height: 570px;
    display: block;
  }
}
