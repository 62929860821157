@import "src/variables/variable";

.logistic-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 209px;
  cursor: pointer;
  transition: all 0.3s;

  border-radius: 100px;
  border: 1px solid #fff;
  background: rgba(203, 203, 203, 0.2);
  backdrop-filter: blur(1.5px);
  padding: 1.5rem 1rem 2rem 1rem;
  margin-bottom: 2rem;

  &:hover {
    scale: 1.1;
  }
}
.logistic-card-text--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 500px;
}
.logistic-card-number {
  color: #fbda70;
  text-align: center;
  font-family: $font;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 182%; /* 36.4px */
  margin-bottom: 1.2rem;
}

.logistic-card-title {
  color: #fff;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: 80%;

  padding-bottom: 5px;
  border-bottom: 2px solid #fbda70;
  margin-bottom: 2rem;
}

.logistic-card-text {
  color: #dfdfdf;
  text-align: center;
  font-family: $font;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 182%; /* 29.12px */
  margin-bottom: 4rem;
}

@media screen and (max-width: 800px) {
  .logistic-card-wrapper {
    margin: 0 auto;
    &:hover {
      scale: 1;
    }
  }
  .logistic-card-text--container {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .logistic-card-text--container {
    height: 250px;
  }
}
