@import "src/variables/variable";

.news-card-wrapper {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 20px;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    scale: 105%;

    .news-card-img {
      filter: brightness(100%);
    }
  }
}

.news-card-img {
  object-fit: cover;
  object-position: center;
  border-radius: 20px 20px 0 0;
  width: 390px;
  height: 250px;
  filter: brightness(80%);
}

.news-card-date {
  color: #828282;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 184.3%; /* 29.488px */
  letter-spacing: 1.6px;
}

.news-card-title {
  color: #101828;
  font-family: $font;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.5%; /* 24.09px */
  letter-spacing: 2.2px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-card-text {
  color: #667085;
  text-overflow: ellipsis;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120.3%; /* 21.654px */
  letter-spacing: 1.8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1650px) {
  .news-card-wrapper {
    max-width: 390px;
  }
}

@media screen and (max-width: 570px) {
  .news-card-wrapper {
    max-width: 350px;
    margin-bottom: 30px;
  }

  .news-card-img {
    width: 100%;
    height: 200px;
  }

  .news-card-date {
    font-size: 14px;
  }

  .news-card-title {
    font-size: 20px;
  }

  .news-card-text {
    font-size: 16px;
  }
}
