.all-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .content-place {
    width: 100%;
    flex: 1 0 auto;
  }
}
