@import "src/variables/variable";

.news-wrapper {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  padding: 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NewsRecentPosts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
    }
    &--Card {
      height: 100%;
      width: 700px;
      img {
        width: 150%;
        height: 350px;
      }
      h4 {
        font-size: 26px;
        -webkit-line-clamp: 3;
      }
    }
    &--CardTwo {
      display: flex;
      flex-direction: column;
      img {
        width: 90%;
        height: 170px;
      }
      h4 {
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
      p {
        font-size: 10px;
      }
      .news-card-wrapper {
        gap: 8px;
      }
    }
}
.NewsRecentPosts-mobile {
  display: none;
}
.news-page-title-line {
  width: 350px;
  background: black;
  height: 1px;
  margin-top: 10px;
}

.news-page-title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  margin-left: 40px;
  .news-page-title {
    color: #101828;
    font-family: $font;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 109.5%; /* 24.09px */
    letter-spacing: 2.2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.news-container {
  display: flex;
  justify-content: center;
  width: 1600px;
  padding: 0 32px;
  flex-wrap: wrap;
  gap: 60px;
}


.pagination {
  margin-top: 70px;
  display: flex;
  width: 100%;
  justify-content: center;
  list-style: none;
  gap: 20px;
    &-prevButt {
      color: #828282;
      font-family: $font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 184.3%; /* 29.488px */
      letter-spacing: 1.6px;
      transition: all 0.5s;
      cursor: pointer;
      padding: 5px;
      user-select: none;
      margin: 0 30px;
        &:hover {
          border-radius: 5px;
          background: rgba(203, 203, 203, 0.2);
        }
    }
    &-break {
      cursor: pointer;
      font-size: 20px;
      color: #828282;
      user-select: none;
      transition: all 0.5s;
        &:hover {
          padding: 3px;
          background: rgba(203, 203, 203, 0.2);
          border-radius: 1px 5px;
        }
    }
    &-active {
      padding: 2px 10px;
      border-radius: 5px;
      background: black;
    }
}


.pagination-pageLink {
  color: #828282;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 184.3%; /* 29.488px */
  letter-spacing: 1.6px;
  transition: all 0.3s;
  cursor: pointer;
  user-select: none;
  &:hover {
    padding: 5px;
    border-radius: 5px;
    background: black;
  }
}

@media screen and (max-width: 1650px) {
  .news-container {
    width: 100%;
    gap: 27px;
  }
}

@media screen and (max-width: 1220px) {
  .NewsRecentPosts {
    margin-top: 100px;
  }

}

@media screen and ( max-width: 1090px ){
  .NewsRecentPosts-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .NewsRecentPosts--Card {
    display: none;
  }
  .NewsRecentPosts--CardTwo {
    display: none;
  }
}

@media screen and (max-width: 570px) {
  .news-page-title-container {
    margin-left: 0;
  }
  .news-container {
    padding: 0 ;
  }
  .news-page-title-line {
    width: 250px;
  }
}
