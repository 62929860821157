@import "src/variables/variable";

.button {
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 30px;
  width: fit-content;
  padding-left: 10px;
  border-radius: 68px;
  z-index: 5;

  color: #fff;
  font-family: $font;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 37.8px */
  letter-spacing: 5.32px;
  transition: all 0.3s;
  svg {
    transition: all 0.3s;
  }

  &:hover {
    background: #fbda70;
    color: #000;
    svg {
      transform: rotate(90deg);

      path {
        stroke: #000000;
      }
    }
  }
}

.size_m {
  font-size: 28px;
}

.size_s {
  font-size: 22px;
}

@media screen and (max-width: 800px) {
  .size_m {
    font-size: 16px;
    letter-spacing: 3.04px;
  }

  .size_s {
    font-size: 16px;
    letter-spacing: 3.04px;
  }
}
