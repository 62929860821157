
@import "../../variables/variable";

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  z-index: 200;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal_content {
  padding: 25px;
  border-radius: 25px;
  background-color: rgba(237, 237, 237, 0.9);
  width: 400px;
  transform: scale(0.5);
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 600px) {
    width: 320px;
    padding: 15px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

.modal_content.active {
  transform: scale(1);
}


.ModalTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
    h2 {
      font-weight: 500;
      font-size: 24px;
      font-family: $font;

      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }
    span {
      font-weight: 700;
      font-size: 24px;
      font-family: $font;

      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
}

.modalCloseButton {
  border: none;
  display: flex;
  margin-right: 10px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  transform: scale(0.9);
    &:hover {
      transform: scale(1.1);
    }
}

.ModalText {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  border-top: #B1B1B1 1px solid;

    p {

      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }

    span {

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
}

.manager {
  width: 85%;
  background: rgba(251, 176, 64, 1);
  border-radius: 30px;
  border: none;
  padding: 12px 10px;
  font-size: 14px;
  font-weight: 500;
  transform: scale(0.9);
  transition: 0.5s;
    span {
      font-weight: 600;
    }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    &TwoDisable {
      display: none;
    }

  @media screen and (max-width: 600px) {
    width: 110%;
    padding: 10px 10px;
  }
    &:hover {
      transform: scale(1);
    }
}
