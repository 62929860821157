@import "src/variables/variable";

.directions-card-wrapper {
  width: 358px;

  .direction-img--wrapper {
    position: relative;
    border-radius: 25px 25px 0 0;
    width: 358px;
    margin-bottom: 30px;
  }

  .direction-img {
    filter: brightness(70%);
    transition: all 0.3s;
  }

  .direction-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #fff;
    text-align: center;
    font-family: $font;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    transition: all 0.2s;
  }

  .direction-text {
    color: #dfdfdf;
    text-align: center;
    font-family: $font;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
}

@media screen and (max-width: 500px) {
  .directions-card-wrapper {
    margin: 0 auto;
    width: 300px;

    .direction-img--wrapper {
      width: 300px;
      margin-bottom: 20px;
    }

    .direction-img {
      width: 300px;
    }

    .direction-title {
      font-size: 4rem;
    }

    .direction-text {
      font-size: 2rem;
    }
  }
}
