.logistic-slider {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  display: none;
}

@media screen and (max-width: 800px) {
  .logistic-slider {
    width: 100%;
    height: 500px;
    display: block;
  }
}
@media screen and (max-width: 500px) {
  .logistic-slider {
    width: 100%;
    height: 400px;
    display: block;
  }
}
