@import "src/variables/variable";

.title {
  color: #fff;
  font-family: $font;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;
  margin-bottom: 3rem;

  &_yellow {
    color: #fbda70;
  }

  &_center {
    text-align: center;
  }
}

.text {
  color: #dfdfdf;
  font-family: $font;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 1.6px;

  &_company {
    margin-bottom: 2rem;
  }

  &_export {
    margin-bottom: 6rem;
  }

  &_logistic {
    text-align: center;
    width: 80%;
    margin-bottom: 5%;
  }

  &_contact {
    margin-bottom: 2.5rem;
  }
}

.about--company {
  background: #15171b;
  width: 100%;
  height: 100%;
  padding: 10% 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &--img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    max-width: 100%;
    height: auto;
  }

  &--top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 2700px;
    margin-bottom: 10%;
    gap: 2rem;

    &--desc {
      display: flex;
      flex-direction: column;
      max-width: 850px;

      &--cards {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 25px;
      }
    }

    &--video {
      position: relative;

      .video {
        width: 621px;
        height: 734px;
      }

      .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;

        border: none;
        background-color: #ffffff;
        border-radius: 50%;
        padding: 20px;

        &_hide {
          display: none;
        }
      }

      .play-border {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &_hide {
          display: none;
        }
      }
    }
  }

  &--bottom {
    display: flex;
    width: 100%;
    max-width: 2700px;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    svg {
      position: absolute;
      top: -140px;
      left: 210px;
      @media screen and (max-width: 1850px) {
        display: none;
      }
    }

    &--desc {
      display: flex;
      flex-direction: column;
      max-width: 850px;

      img {
        width: 164.308px;
        height: 164.308px;
        margin-bottom: 4.5rem;
      }
    }

    &--img {
      max-width: 100%;
    }
  }
}

.about--directions {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #2e2e2e;
  padding: 5% 5%;

  &--cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 2700px;
    gap: 2rem;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.about--slider {
  padding: 10% 5%;
  background: #15171b;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
    &--container {
      max-width: 2700px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 100px;
    }

  .about--slider-desc {
    max-width: 864px;
  }
}

@media screen and (max-width: 1800px) {
  .about--company {
    &--top {
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1500px){
  .about--slider {
    flex-direction: column;
    &--container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .about--company {
    &--top {
      flex-wrap: wrap;
      &--desc {
        &--cards {
          align-items: center;
          justify-content: center;
        }
      }
    }

    &--bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      position: relative;

      &--img {
        position: absolute;
        width: 164.308px;
        height: 164.308px;
        top: 0;
        right: 10%;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .about--slider-img {
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .about--company {
    &--top {
      &--video {
        position: relative;

        .video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .about--directions {
    &--cards {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .about--company {
    padding-top: 15%;
  }

  .about--company--bottom {
    &--desc {
      img {
        width: 100px;
        height: 100px;
      }
    }

    &--img {
      position: absolute;
      width: 100px;
      height: 100px;
      top: 0;
      right: 10%;
    }
  }

  .about--directions {
    background: #2e2e2e;
    padding: 5% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--cards {
      display: none;
    }
  }
}
