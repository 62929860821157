.corp-slider {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  display: none;
}

.corpSwiper {
  height: 480px;
}

@media screen and (max-width: 500px) {
  .corp-slider {
    width: 100%;
    height: 500px;
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .corp-slider {
    height: 470px;
  }
  .corpSwiper {
    height: 450px;
  }
}
