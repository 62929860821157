@import "src/variables/variable";

.contacts-page-banner {
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/images/contactsBanner.png') center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;

  &--content {
    width: 100%;
    display: flex;
    max-width: 2700px;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;

    &-logo {
      max-width: 100%;
    }

    &-desc {
      display: flex;
      flex-direction: column;
      gap: 50px;

      &-listItem {
        display: flex;
        align-items: center;
        gap: 20px;
        color: #fff;
        font-family: $font;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        transition: all 0.3s;
      }
    }
  }
}

.contacts-page-contacts {
  padding: 7% 0 3% 0;
  background: #15171b;

  &--purchase {
    padding: 0 5%;
    margin-bottom: 8%;

    &--cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 50px;
    }
  }
}

.contacts-page-banner--content-desc-title {
  color: #fff;
  font-family: $font;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 109.5%; /* 70.08px */
  transition: all 0.3s;
}

.contacts-page-contacts--purchase--cardsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1713px) {
  .contacts-page-contacts--purchase--cards {
    max-width: 1100px;
  }
}

@media screen and (max-width: 1460px) {
  .contacts-page-banner--content-desc-title {
    font-size: 54px;
  }

  .contacts-page-banner {
    width: 100%;
    height: 100%;

    &--content {
      flex-direction: column-reverse;
      gap: 70px;
      padding: 100px 5%;

      &-desc {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 35px;

        &-listItem {
          display: flex;
          align-items: center;
          gap: 20px;
          color: #fff;
          font-family: $font;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .contacts-page-banner--content-desc-title {
    font-size: 34px;
  }

  .contacts-page-banner {
    &--content {
      gap: 50px;

      &-desc {
        align-items: flex-start;

        &-listItem {
          font-size: 16px;
        }
      }
    }
  }
}
