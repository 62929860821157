@import "src/variables/variable";

.achievement-card-wrapper {
  padding: 24px;
  border-radius: 20px;
  width: 400px;
  border: 1px solid rgba(51, 51, 51, 0.9);
  background: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  transition: all 0.3s;
  min-height: 160px;

  &:hover {
    box-shadow: rgba(251, 218, 112, 0.35) 5px 5px 15px;
    scale: 110%;
  }
}

.achievement-number {
  color: #fbda70;
  font-family: $font;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
}

.achievement-text {
  color: #dfdfdf;
  font-family: $font;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

@media screen and (max-width: 800px) {
  .achievement-card-wrapper {
    padding: 16px;
    width: 300px;
  }
  .achievement-text {
    font-size: 13px;
    line-height: 2rem;

  }
}
