@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&display=swap');


html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 16px;

  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }

  @media screen and (max-width: 500px) {
    font-size: 8px;
  }
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  font-family: Montserrat, sans-serif;
}
a {
  text-decoration: none;
  color: #282828;
}
button {
  cursor: pointer;
}
