@import "src/variables/variable";

.AboutUsSlider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  position: relative;
}

.AboutUsSliderTitle {
  width: 50%;
  text-align: center;
  position: absolute;
  top: 0;
  color: #FFF;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 168.8%; /* 40.512px */
  border-radius: 0 0 20px 20px;
  padding: 10px 25px;
  background: rgba(0, 0, 0, 0.6);
}

.AboutUsSliderImg {
  width: 60%;
  border-radius: 20px;
}

.AboutUsSliderImg--container {
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutSlider {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .AboutUsSliderTitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 1100px) {
  .AboutUsSliderTitle {
    padding: 10px 10px;
  }
  .AboutSlider {
    width: 70%;
  }
  .AboutUsSliderImg {
    width: 70%;
  }
}

@media screen and (max-width: 800px) {
  .AboutSlider {
    width: 60%;
  }
}

@media screen and (max-width: 650px) {
  .AboutUsSliderImg {
    width: 72%;
  }
  .AboutSlider {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .AboutUsSliderTitle {
    width: 60%;
    padding: 10px 10px;
  }
}
