@import "src/variables/variable";

.single-news-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1080px;
}

.single-news-img {
  width: 95%;
  height: 500px;
  border-radius: 30px;
  margin-bottom: 55px;
}

.single-news-containerTopPart {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.single-news-title {
  color: black;
  font-family: $font;
  text-align: left;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.2px;
}

.single-newsTitle-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.single-news-BackButton {
  max-width: 300px;
  background: transparent;
  color: #656565;
  font-family: $font;
  letter-spacing: 1.5px;
  margin-bottom: 50px;
  font-size: 18px;
  border: none;
  transition: all 0.5s;
    svg {
      margin-right: 15px;
      transition: all 0.5s;
    }
    &:hover {
      letter-spacing: 0.8px;
        svg {
          margin-right: 7px;
        }
    }
}
.news-wrapper {
  position: relative;
}

.single-news-blackHead {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 8vh;
  position: fixed;
  top: 0;
}

.single-news-text {
  color: #667085;
  text-overflow: ellipsis;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.3%; /* 21.654px */
  letter-spacing: 1.8px;
}

.single-news-bottom {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  margin-top: 30px;

  span {
    color: #333333;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 184.3%; /* 29.488px */
    letter-spacing: 1.6px;
  }
}

@media screen and (max-width: 1200px){
  .single-news-title {
    font-size: 30px;
  }
  .single-news-img {
    width: 55%;
    height: 300px;
  }
}

@media screen and (max-width: 800px){
  .single-news-containerTopPart {
    flex-direction: column;
    gap: 50px;
  }
  .single-news-img {
    width: 65%;
    height: 250px;
  }
  .single-news-containerTopPart {
    margin-top: 100px;
  }
}

@media screen and (max-width: 500px){
  .single-news-img {
    width: 100%;
  }

  .single-news-bottom {
    span {
      font-size: 12px;
    }
  }

  .single-news-title {
    font-size: 25px;
  }

}

@media screen and (max-width: 360px) {
  .single-news-title {
    font-size: 20px;
    width: 250px;
  }
}
