@import "../../../variables/variable";

.purchaseContainer {
  background: rgba(21, 23, 27, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    padding: 120px 10px 50px 10px;
  }
   &--topPart {
     width: 100%;
     max-width: 1800px;
     padding: 150px 120px 50px 120px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     overflow: hidden;

     @media screen and (max-width: 1080px) {
       flex-direction: column;
       padding: 0;
     }
   }
}
.purchase-leftContent-title {
  color: #FFF;
  font-family: $font;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  line-height: 109.5%; /* 70.08px */
  letter-spacing: 12.16px;
  text-align: left;

  @media screen and (max-width: 600px) {
    font-size: 32px;
    letter-spacing: 8px;
  }

    span {
      color: #FBB040;
      font-family: $font;
      font-size: 54px;
      font-style: normal;
      font-weight: 800;
      line-height: 109.5%; /* 70.08px */
      letter-spacing: 12.16px;
      text-align: left;

      @media screen and (max-width: 600px) {
        font-size: 32px;
        letter-spacing: 8px;
      }
    }
}
.purchase-leftContent-text {
  color: #FFF;
  text-align: left;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 154.3%; /* 44.232px */
  letter-spacing: 0.48px;
  max-width: 800px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }

    span {
      color: #FBB040;
      text-align: left;
      font-family: $font;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 154.3%; /* 44.232px */
      letter-spacing: 0.48px;
      max-width: 800px;

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
}

.purchase-leftContent {
  gap: 40px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px 0 auto 0;
}



.purchase-map {
  position: relative;
  overflow: visible;

    path {
        transition: all 0.7s;
        &:hover {
          fill: rgba(94, 93, 93, 0.85);
          transform: scale(1.01);
        }
    }



  @media screen and (max-width: 1080px) {
    margin-top: 50px;
  }

    svg {
      position: absolute;
    }

  .southPart {
    fill: rgba(217, 43, 43, 0.7);
    transition: all 0.7s;
      &:hover {
        fill: rgba(217, 43, 43, 0.95);
        transform: scale(1.02);
      }
  }

  .centerPart {
    fill: rgba(235, 228, 49, 0.7);
    transition: all 0.7s;
      &:hover {
        fill: rgba(235, 228, 49, 0.95);
        transform: scale(1.02);
      }
  }

  .northPart {
    fill: rgba(88, 65, 225, 0.7);
    transition: all 0.7s;
      &:hover {
        fill: rgba(88, 65, 225, 0.97);
        transform: scale(1.03);
      }
  }
  .jurjPart {
    fill: green;
    transition: all 0.7s;

    &:hover {
      fill: black;
      transform: scale(1.03);
    }
  }
}

.purchase-mapImg {
  width: 100%;
}

.tooltip {
  position: fixed;
  z-index: 100;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translate(-50%, -100%);
}

.tooltip.visible {
  opacity: 1;
}

.mapPart {
  display: flex;
  align-items: center;
  gap: 20px;
  h4 {
    font-size: 20px;
    font-weight: 700;
    color: white;
  }
  .mapPartLine {
    height: 15px;
    border-radius: 10px;
    background: yellow;
    width: 30px;
  }
  .mapPartButton {
    margin-top: 20px;
    padding: 10px 20px;
  }
}


////// part color

.partColor {
  display: flex;
  max-width: 400px;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
        & h3 {
          font-family: $font;
          font-weight: 700;
          font-size: 20px;
          color: white;
            @media screen and (max-width: 500px) {
              font-size: 16px;
            }
        }
        & span {
          font-weight: 400;
          font-size: 20px;
          font-family: $font;
          color: white;
        }
    }
    &__line {
      width: 100px;
      height: 15px;
      border-radius: 4px;
    }
}



.pricesTable-container {
  width: 100%;
   &--title {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     justify-content: flex-start;
     gap: 10px;
     margin-bottom: 20px;
      & h2 {
        font-family: $font;
        font-weight: 700;
        font-size: 22px;
        color: #FBB040;
      }
      & span {
        font-family: $font;
        font-weight: 500;
        font-size: 16px;
        color: white;
      }
   }
  @media screen and (max-width: 555px) {
    display: none;
  }
}
.pricesTable-head {
  background: rgba(244, 246, 248, 1);
}

.mobile-accordionPrices {
  display: none;
  margin-top: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 555px) {
    display: block;
  }
}




/////////////////////



.chart-container {
  width: 100%;
  padding: 0 20px 0 20px;
  border-radius: 15px;
  background: #fff;
    @media screen and (max-width: 650px) {
      padding: 0 5px 0 5px;
    }
    &--selectPart {
      padding-top: 17px;
      margin-left: 50px;
      display: flex;
      align-items: center;
      gap: 10px;
        & label {
          font-family: $font;
          font-weight: 700;
          font-size: 14px;
          color: rgba(99, 115, 129, 1);
          margin-right: 10px;
        }
    }
}

.pricesTable-container-wrapp {
  width: 100%;
  max-width: 1800px;
  padding: 0 100px 0 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 150px;
  margin-top: 100px;
    @media screen and (max-width: 600px) {
      padding: 0 25px 0 25px;
    }
    @media screen and (max-width: 400px) {
      padding: 0;
    }
}




