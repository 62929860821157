@import "src/variables/variable";

.main-page-wrapper {
  overflow: hidden;
}

.newsMainPageBlock {
  background: #15171B;
  padding: 5% 5% 10% 5%;
    &-topPart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;
    }
}

.main-page-banner {
  background: linear-gradient(
    193deg,
    rgba(0, 0, 0, 0.31) 0%,
    rgba(0, 0, 0, 0.29) 100%
  );
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 8% 8% 0 8%;

  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &--title {
    color: #fff;
    /* H1 */
    font-family: $font;
    font-size: 120px;
    font-style: normal;
    max-width: 1000px;
    font-weight: 900;
    line-height: 130px; /* 108.333% */
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  &--info {
    width: 80vw;
    border-radius: 25px 25px 0 0;
    border: 1px solid #000;
    background: rgba(#000, 0.6);
    display: flex;
    justify-content: space-around;
    gap: 30px;
    padding: 38px 0 33px 0;
    margin-top: 100px;

    &--column {
      display: flex;
      flex-direction: column;
      max-width: 345px;
    }

    &--number {
      color: #fbda70;
      font-family: $font;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 14px;
    }

    &--title {
      color: #fff;

      /* H4 */
      font-family: $font;
      font-size: 26.409px;
      font-style: normal;
      font-weight: 700;
      line-height: 41.081px; /* 155.556% */
    }

    &--desc {
      color: #939393;
      font-feature-settings:
        'clig' off,
        'liga' off;

      /* Paragraph Light */
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      letter-spacing: 0.5px;
    }
  }
}

.main-page-about {
  background: #15171b;
  padding: 5% 5% 10% 5%;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ellipse {
    position: absolute;
    top: -10%;
    left: 0;
  }

  .ellipse2 {
    position: absolute;
    bottom: -25%;
    left: 50%;
    transform: translateX(-50%);
  }

  &--company {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 2100px;
    gap: 40px;
    margin-bottom: 10%;

    &--desc {
      display: flex;
      flex-direction: column;
      max-width: 858px;

      &--title {
        color: #fff;
        font-family: $font;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 109.5%; /* 70.08px */
        letter-spacing: 16.32px;
        text-transform: uppercase;
        margin-bottom: 32px;
      }

      &--text {
        color: #fff;
        text-align: justify;
        font-family: $font;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 184.3%; /* 36.86px */
        letter-spacing: 0.4px;
        margin-bottom: 30px;
      }

      &--advantages-list {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin-bottom: 50px;

        &--item {
          display: flex;
          align-items: center;
          gap: 25px;

          &-text {
            color: #fbda70;
            font-family: $font;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 134.5%; /* 26.9px */
            letter-spacing: 3.1px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &--cards {
    display: flex;
    width: 100%;
    max-width: 2100px;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 720px;

    &--title {
      color: #fff;
      font-family: $font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 109.5%;
      letter-spacing: 5.58px;
      text-transform: uppercase;

      span {
        color: #fbda70;
      }
    }

    &--text {
      color: #c5c5c5;
      font-family: $font;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.5%; /* 28.5px */
      letter-spacing: 2px;
    }
  }
}

.main-page-products {
  padding: 5%;
  background: #2e2e2e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 2700px;
    margin-bottom: 4%;

    &--text {
      display: flex;
      align-items: center;
      gap: 40px;

      &--title {
        display: flex;
        flex-direction: column;
        gap: 35px;

        &_white {
          color: #fff;
          font-family: $font;
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: 109.5%; /* 70.08px */
          letter-spacing: 10px;
          text-transform: uppercase;
        }

        &_orange {
          color: #fbda70;
          font-family: $font;
          font-size: 58px;
          font-style: normal;
          font-weight: 700;
          line-height: 109.5%; /* 63.51px */
          letter-spacing: 4.06px;
          text-transform: uppercase;
        }
      }

      &--dash {
        width: 58px;
        height: 7px;
        background: #fff;
      }

      &--desc {
        color: #fff;
        font-family: $font;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 135%; /* 27px */
        letter-spacing: 3.8px;
        max-width: 352px;
      }
    }
  }

  &--cards {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 2700px;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--card {
    display: flex;
    flex-direction: column;
    gap: 29px;

    &--name {
      color: #fff;
      font-family: $font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 109.5%; /* 39.42px */
      letter-spacing: 3.6px;
      text-align: center;
    }

    &--desc-list {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &--item {
        display: flex;
        align-items: center;
        gap: 20px;

        &-icon {
          width: 30px;
          height: 30px;
        }

        &-name {
          color: #fff;
          font-family: $font;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 109.5%; /* 24.09px */
          letter-spacing: 2.2px;
        }
      }
    }
  }
}

.main-page-accordion {
  background: #15171b;
  padding: 10% 5%;

  &--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 94px;

    &--title {
      color: #fff;
      text-align: right;
      font-family: $font;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: 109.5%; /* 70.08px */
      letter-spacing: 16.32px;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 1600px) {
  .main-page-about--cards {
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }

  .main-page-products--head {
    flex-direction: column;
    gap: 20px;

    &--text {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 1400px) {
  .main-page-about--company {
    flex-direction: column-reverse;
    align-items: center;

    &--desc {
      align-items: center;
    }
  }
}

@media screen and (max-width: 1200px) {
  .newsMainPageBlock {
    &-topPart {
      flex-direction: column;
      gap: 30px;
      justify-content: flex-start;
      margin-bottom: 50px;
    }
  }
  .main-page-banner {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 12% 5% 0 5%;

    &--title {
      font-size: 120px * 0.8;
      line-height: 130px * 0.8; /* 108.333% */
    }

    &--info {
      padding: 38px 10px 33px 10px;
      margin-top: 10%;

      &--column {
        display: flex;
        flex-direction: column;
        max-width: 345px;
      }

      &--title {
        font-size: 26.409px * 0.8;
        line-height: 41.081px; /* 155.556% */
      }

      &--desc {
        font-size: 18px * 0.8;
        line-height: 28px * 0.8; /* 155.556% */
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .main-page-banner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 12% 5% 0 5%;

    &--title {
      font-size: 120px * 0.6;
      line-height: 130px * 0.6; /* 108.333% */
    }

    &--info {
      padding: 38px 10px 33px 10px;
      width: 60vw;
      border-radius: 25px 25px 0 0;
      border: 1px solid #000;
      background: rgba(#000, 0.6);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin: 10% auto 0 auto;

      &--title {
        font-size: 26.409px * 0.8;
        line-height: 41.081px; /* 155.556% */
      }

      &--desc {
        font-size: 18px * 0.8;
        line-height: 28px * 0.8; /* 155.556% */
      }
    }
  }

  .main-page-about {
    background: #15171b;
    padding: 5% 5% 10% 5%;
    position: relative;
    overflow: hidden;

    &--company {
      &--desc {
        &--title {
          font-size: 64px * 0.8;
          line-height: 109.5% * 0.8; /* 70.08px */
          letter-spacing: 16.32px * 0.8;
        }

        &--text {
          font-size: 20px * 0.8;
          line-height: 184.3% * 0.8; /* 36.86px */
          letter-spacing: 0.4px * 0.8;
        }

        &--advantages-list {
          display: flex;
          flex-direction: column;
          gap: 35px;
          margin-bottom: 50px;

          &--item {
            display: flex;
            align-items: center;
            gap: 25px;

            &-text {
              font-size: 20px * 0.8;
              letter-spacing: 3.1px * 0.8;
            }

            &-img {
              width: 37px;
              height: 37px;
            }
          }
        }
      }

      &--img {
        max-width: 400px;
      }
    }

    &--cards {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &--card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      width: 100%;

      &--title {
        font-size: 36px * 0.8;
        line-height: 109.5%;
        letter-spacing: 5.58px * 0.8;
        text-align: center;

        span {
          color: #fbda70;
        }
      }

      &--img {
        max-width: 400px;
      }

      &--text {
        font-size: 20px * 0.8;
        line-height: 142.5% * 0.8; /* 28.5px */
        letter-spacing: 2px * 0.8;
      }
    }
  }

  .main-page-products {
    padding: 5%;
    background: #2e2e2e;

    &--head {
      flex-direction: column;
      gap: 20px;

      &--text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;

        &--title {
          display: flex;
          flex-direction: column;
          gap: 35px;

          &_white {
            color: #fff;
            font-family: $font;
            font-size: 64px * 0.6;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%; /* 70.08px */
            letter-spacing: 10px * 0.8;
            text-transform: uppercase;
          }

          &_orange {
            color: #fbda70;
            font-family: $font;
            font-size: 58px * 0.6;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%; /* 63.51px */
            letter-spacing: 4.06px * 0.8;
            text-transform: uppercase;
          }
        }

        &--dash {
          width: 58px * 0.8;
        }

        &--desc {
          color: #fff;
          font-family: $font;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 135%; /* 27px */
          letter-spacing: 3.8px;
          max-width: 352px;
        }
      }
    }

    &--cards {
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }

    &--card {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &--name {
        color: #fff;
        font-family: $font;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 109.5%; /* 39.42px */
        letter-spacing: 3.6px;
        text-align: center;
      }

      &--desc-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &--item {
          display: flex;
          align-items: center;
          gap: 20px;

          &-icon {
            width: 30px;
            height: 30px;
          }

          &-name {
            color: #fff;
            font-family: $font;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 109.5%; /* 24.09px */
            letter-spacing: 2.2px;
          }
        }
      }
    }
  }

  .main-page-accordion {
    &--head {
      &--title {
        font-size: 64px * 0.6;
        letter-spacing: 16.32px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .main-page-accordion {
      &--head {
        flex-direction: column;
        gap: 30px;
      }
  }
  .main-page-banner {
    width: 100%;
    height: 100%;
    padding: 25% 5% 0 5%;

    &--title {
      font-size: 42px;
      line-height: 42px; /* 100% */
    }

    &--info {
      padding: 48px 27px;
      width: 100%;
      gap: 25px;
      margin: 20% auto 20% auto;

      &--number {
        margin-bottom: 4px;
      }

      &--title {
        font-size: 26.409px;
        line-height: 41.081px; /* 155.556% */
      }

      &--desc {
        font-size: 16px;
        line-height: 28px; /* 155.556% */
      }
    }
  }

  .main-page-about {
    background: #15171b;
    padding: 20% 5% 10% 5%;
    position: relative;
    overflow: hidden;

    &--company {
      &--desc {
        margin-bottom: 100px;

        &--title {
          font-size: 32px;
          line-height: 109.5%;
          letter-spacing: 8.16px;
          text-align: center;
        }

        &--text {
          font-size: 16px;
          line-height: 174.8%; /* 36.86px */
          letter-spacing: 0.32px;
        }

        &--advantages-list {
          display: flex;
          flex-direction: column;
          gap: 35px;
          margin-bottom: 50px;

          &--item {
            display: flex;
            align-items: center;
            gap: 15px;

            &-text {
              font-size: 14px;
              letter-spacing: 2.48px;
            }

            &-img {
              width: 37px;
              height: 37px;
            }
          }
        }
      }

      &--img {
        max-width: 314px;
      }
    }

    &--card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      width: 100%;

      &--title {
        font-size: 24px;
        line-height: 142.5%;
        letter-spacing: 3.72px;
        text-align: center;

        span {
          color: #fbda70;
        }
      }

      &--img {
        max-width: 295px;
      }

      &--text {
        font-size: 16px;
        line-height: 142.5%; /* 28.5px */
        letter-spacing: 1.6px;
        color: #c5c5c5;
      }
    }
  }

  .main-page-products {
    padding: 5%;
    background: #2e2e2e;

    &--head {
      flex-direction: column;
      gap: 20px;

      &--text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        &--title {
          display: flex;
          flex-direction: column;
          gap: 15px;

          &_white {
            color: #fff;
            font-family: $font;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%; /* 70.08px */
            letter-spacing: 6.24px;
            text-transform: uppercase;
          }

          &_orange {
            color: #fbda70;
            font-family: $font;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 109.5%; /* 63.51px */
            letter-spacing: 1.68px;
            text-transform: uppercase;
          }
        }

        &--dash {
          width: 19px;
          height: 3px;
        }

        &--desc {
          color: #fff;
          font-family: $font;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 135%; /* 27px */
          letter-spacing: 3.04px;
          max-width: 352px;
        }
      }
    }

    &--cards {
      flex-direction: column;
      gap: 50px;
      align-items: center;
      margin-bottom: 10%;
      display: none;
    }

    &--card {
      display: flex;
      flex-direction: column;
      gap: 29px;

      &--name {
        color: #fff;
        font-family: $font;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 109.5%; /* 39.42px */
        letter-spacing: 2.4px;
        text-align: center;
      }

      &--desc-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &--item {
          display: flex;
          align-items: center;
          gap: 20px;

          &-icon {
            width: 30px;
            height: 30px;
          }

          &-name {
            color: #fff;
            font-family: $font;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 109.5%; /* 24.09px */
            letter-spacing: 2px;
          }
        }
      }
    }
  }

  .main-page-accordion {
    &--head {
      &--title {
        font-size: 32px;
        letter-spacing: 8.16px;
      }
    }
  }
}

@media screen and (max-height: 815px)  {
  .main-page-banner--info {
    padding: 20px 5px 43px 10px;
    margin-top: 50px;
  }
}
